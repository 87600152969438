import styled from '@emotion/styled'

export default styled('section')`
  & > div {
    position: relative;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.color.background};

    & > div {
      left: -1px;
      width: calc(100% + 3px);
      bottom: -1px;
      background: linear-gradient(${({ deg }) => deg}deg,
      ${({ theme }) => theme.color.primary} 0%, ${({ theme }) => theme.color.default} 60%);
      position: relative;

      & > svg {
        width: 100%;
        height: 100%;
        max-width: 980px;
        display: block;
      }
    }
  }
`
