import React from 'react'
import { ThemeProvider } from 'emotion-theming'

import Styling from './Styling'
import Header from '../Header'
import Home from '../Home'
import Footer from '../Footer'
import bsTheme from '../lib/theme'

export default () => (
  <ThemeProvider theme={bsTheme}>
    <Styling>
      <Header text='Bueth Software' />
      <Home />
      <Footer />
    </Styling>
  </ThemeProvider>
)
