const bsTheme = {
  color: {
    primary: '#be1e2e',
    default: '#231F20',
    secondary: '#D1D5D7',
    background: '#F5F4F3',
  },
  transition: 'all 0.2s ease-out',
  radius: '10px',
}

export default bsTheme
