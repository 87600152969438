// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import React, { Component } from 'react'
import { hot } from 'react-hot-loader'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faMale, faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import Main from './Main'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDur7KjI9NZld659JwooiaEPf-QInqJvH4",
  authDomain: "bueth-software.firebaseapp.com",
  projectId: "bueth-software",
  storageBucket: "bueth-software.appspot.com",
  messagingSenderId: "208804733956",
  appId: "1:208804733956:web:925a4d8f6023b5a8958151",
  measurementId: "G-Y8YWDZHESG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

library.add(fab, faMale, faGlobe, faEnvelope)

class App extends Component {
  render() { return <Main /> }
}

export default hot(module)(App)
