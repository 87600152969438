import styled from '@emotion/styled'

export default styled('section')`
  position: fixed;
  transition: bottom 0.3s ease-in;
  background: #F5F4F3;
  bottom: ${({ showImprint }) => (showImprint ? '196px' : 0)};
  left: 0;
  right: 0;
  height: 30px;
  text-align: center;
  z-index: 20;

  & > button {
    background: transparent;
    border: 0;
    padding: 5px;
    cursor: pointer;

    & > span {
      color: ${({ theme }) => theme.color.default};
      margin-right: 20px;
    }    
  }

  & > div {
    overflow: auto;
    background: #F5F4F3;
    border-top: 1px solid ${({ theme }) => theme.color.primary};
    transition: height 0.3s ease-in;
    height: ${({ showImprint }) => (showImprint ? '200px' : 0)};
    & > div {
      padding: 15px;

      & > a {
        color: ${({ theme }) => theme.color.primary};
      }
    }
  }
`
