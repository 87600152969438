/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root'),
  )
}

const RootContainer = require('./App').default
const NextRootContainer = require('./App').default

render(RootContainer)

if (module.hot) {
  module.hot.accept('./App', () => {
    render(NextRootContainer)
  })
}
