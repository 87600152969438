import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Styling from './Styling'
import mx from '../lib/getMailTo'

let current = 0

export default () => {
  const order = ['', 'website', '', 'igfb', '', 'email', '', 'me']
  const [highlight, setHighlight] = useState(order[current])

  useEffect(() => {
    if (current < order.length - 1) {
      window.setTimeout(() => {
        current += 1
        setHighlight(order[current])
      }, 1000)
    }
  }, [highlight])

  return (
    <Styling highlight={highlight}>
      <div className='grid-sep-top'>
        <div>
          <button
            type='button'
            className={order[current] === 'me' ? 'current' : null}
            onMouseOver={() => setHighlight('me')}
            onFocus={() => setHighlight('me')}
            onMouseOut={() => setHighlight('')}
            onBlur={() => setHighlight('')}
          >
            <FontAwesomeIcon icon='male' />
            {' '}
            me
          </button>
        </div>
        <div />
        <div>
          <button
            type='button'
            className={order[current] === 'website' ? 'current' : null}
            onMouseOver={() => setHighlight('website')}
            onFocus={() => setHighlight('website')}
            onMouseOut={() => setHighlight('')}
            onBlur={() => setHighlight('')}
          >
            <FontAwesomeIcon icon='globe' />
            {' '}
            website
          </button>
        </div>
        <div />
        <div />
        <div />
      </div>
      <div className='grid-main'>
        <div>alex</div>
        <div>{String.fromCharCode(2 ** 6)}</div>
        <div>buethsoftware</div>
        <div>.com</div>
      </div>
      <div className='grid-sep-bottom'>
        <div />
        <div />
        <div />
        <div />
        <div>
          <button
            type='button'
            className={order[current] === 'igfb' ? 'current' : null}
            onClick={() => {
              window.location.href = '//www.instagram.com/buethsoftware/'
            }}
            onMouseOver={() => setHighlight('igfb')}
            onFocus={() => setHighlight('igfb')}
            onMouseOut={() => setHighlight('')}
            onBlur={() => setHighlight('')}
          >
            <FontAwesomeIcon icon={['fab', 'instagram']} />
            {' '}
            Instagram
          </button>
          <button
            type='button'
            className={order[current] === 'igfb' ? 'current' : null}
            onClick={() => {
              window.location.href = '//www.facebook.com/buethsoftware/'
            }}
            onMouseOver={() => setHighlight('igfb')}
            onFocus={() => setHighlight('igfb')}
            onMouseOut={() => setHighlight('')}
            onBlur={() => setHighlight('')}
          >
            <FontAwesomeIcon icon={['fab', 'facebook']} />
            {' '}
            facebook
          </button>
        </div>
        <div />
        <div>
          <button
            type='button'
            className={order[current] === 'email' ? 'current' : null}
            onClick={() => {
              window.location.href = mx(['alex', 'buethsoftware', 'com'])
            }}
            onMouseOver={() => setHighlight('email')}
            onFocus={() => setHighlight('email')}
            onMouseOut={() => setHighlight('')}
            onBlur={() => setHighlight('')}
          >
            <FontAwesomeIcon icon='envelope' />
            {' '}
            Email
          </button>
        </div>
        <div className='hello' />
        <div />
        <div>
          Alexander Büth
          <br />
          Senior Software Developer
          <br />
          aus Köln / from Cologne
          <br />

          <button
            type='button'
            className={order[current] === 'me' ? 'current' : null}
            onClick={() => {
              window.location.href = '//www.linkedin.com/in/alexander-bueth-9b6aa271/'
            }}
          >
            <FontAwesomeIcon icon={['fab', 'linkedin']} />
            {' '}
            LinkedIn
          </button>
          <button
            type='button'
            className={order[current] === 'me' ? 'current' : null}
            onClick={() => {
              window.location.href = '//www.xing.com/profile/Alexander_Bueth/cv'
            }}
          >
            <FontAwesomeIcon icon={['fab', 'xing']} />
            {' '}
            Xing
          </button>
          <button
            type='button'
            className={order[current] === 'me' ? 'current' : null}
            onClick={() => {
              window.location.href = 'https://gitlab.com/bueth-software'
            }}
          >
            <FontAwesomeIcon icon={['fab', 'gitlab']} />
            {' '}
            GitLab
          </button>
        </div>
      </div>
    </Styling>
  )
}
