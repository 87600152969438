import React from 'react'

import Logo from './Logo'
import Styling from './Styling'
import useMousePosition from '../lib/useMousePosition'
import getDegFromPosition from '../lib/getDegFromPosition'

export default () => (
  <Styling deg={getDegFromPosition(useMousePosition())}>
    <div>
      <div>
        <Logo />
      </div>
    </div>
  </Styling>
)
