import styled from '@emotion/styled'

export default styled('section')`
  position: relative;
  padding: 6vw 0;

  & button {
    transition: ${({ theme }) => theme.transition};
    background: transparent;
    font-size: 18px;
    color: ${({ theme }) => theme.color.secondary};
    border: 0;
    padding: 0;
    margin: 3px 10px;
    cursor: pointer;
    &:hover, &.current {
      color: ${({ theme }) => theme.color.default};
    }
  }

  @media(min-width: 980px) {
    padding: 50px 0;
  }

  & > div.grid-sep-top {
    display: grid;
    grid-template-columns: 6fr 3fr 24fr;
    width: calc(100% - 20px);
    max-width: 960px;
    margin: 0 auto;

    & > div {
      transition: ${({ theme }) => theme.transition};
      &:nth-of-type(1), :nth-of-type(3) {
        height: 26px;
        text-align: center;
      }
      &:nth-of-type(4) {
        height: 50px;
        border-top: 2px solid ${({ theme }) => theme.color.secondary};
        border-left: 2px solid ${({ theme }) => theme.color.secondary};
        border-right: 2px solid ${({ theme }) => theme.color.secondary};
        border-top-left-radius: ${({ theme }) => theme.radius};
        border-top-right-radius: ${({ theme }) => theme.radius};
        border-color: ${({ highlight, theme }) => (highlight === 'me' ? theme.color.primary : theme.color.secondary)};
      }
      &:nth-of-type(6) {
        height: 50px;
        border-color: ${({ theme }) => theme.color.secondary};
        border-top: 2px solid ${({ theme }) => theme.color.secondary};
        border-left: 2px solid ${({ theme }) => theme.color.secondary};
        border-right: 2px solid ${({ theme }) => theme.color.secondary};
        border-top-left-radius: ${({ theme }) => theme.radius};
        border-top-right-radius: ${({ theme }) => theme.radius};
        border-color: ${({ highlight, theme }) => (highlight === 'website' ? theme.color.primary : theme.color.secondary)};
      }
    }
  }

  & > div.grid-main {
    display: grid;
    grid-template-columns: 6fr 3fr 18fr 6fr;
    width: calc(100% - 20px);
    max-width: 960px;
    margin: -10px auto 0;

    & > div {
      font-size: 8vw;
      text-align: center;
      transition: ${({ theme }) => theme.transition};

      @media(min-width: 980px) {
        font-size: 80px;
      }
      &:nth-of-type(1) {
        color: ${({ highlight, theme }) => (['me', 'email'].includes(highlight) ? theme.color.default : theme.color.secondary)};
      }
      &:nth-of-type(2) {
        color: ${({ highlight, theme }) => (['igfb', 'email'].includes(highlight) ? theme.color.default : theme.color.secondary)};
      }
      &:nth-of-type(3) {
        color: ${({ highlight, theme }) => (['website', 'igfb', 'email'].includes(highlight) ? theme.color.default : theme.color.secondary)};
      }
      &:nth-of-type(4) {
        color: ${({ highlight, theme }) => (['website', 'email'].includes(highlight) ? theme.color.default : theme.color.secondary)};
      }
    }
  }

  & > div.grid-sep-bottom {
    display: grid;
    max-width: 960px;
    margin: -10px auto 0;
    width: calc(100% - 20px);
    grid-template-columns: 6fr 3fr 18fr 6fr;

    & > div {
      min-height: 50px;
      transition: ${({ theme }) => theme.transition};
      &:nth-of-type(1) {
        border-left: 2px solid ${({ theme }) => theme.color.secondary};
        border-color: ${({ highlight, theme }) => (['email'].includes(highlight) ? theme.color.primary : theme.color.secondary)};
      }
      &:nth-of-type(2) {
        grid-column: 2 / 4;
        border-bottom-left-radius: ${({ theme }) => theme.radius};
        border-bottom-right-radius: ${({ theme }) => theme.radius};
        border-left: 2px solid ${({ theme }) => theme.color.secondary};
        border-right: 2px solid ${({ theme }) => theme.color.secondary};
        border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
        border-color: ${({ highlight, theme }) => (['igfb'].includes(highlight) ? theme.color.primary : theme.color.secondary)};
      }
      &:nth-of-type(3) {
        border-right: 2px solid ${({ theme }) => theme.color.secondary};
        border-color: ${({ highlight, theme }) => (['email'].includes(highlight) ? theme.color.primary : theme.color.secondary)};
      }
      &:nth-of-type(4) {
        border-bottom-left-radius: ${({ theme }) => theme.radius};
        border-left: 2px solid ${({ theme }) => theme.color.secondary};
        border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
        border-color: ${({ highlight, theme }) => (['email'].includes(highlight) ? theme.color.primary : theme.color.secondary)};
      }
      &:nth-of-type(5) {
        text-align: center;
        grid-column: 2 / 4;
        border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
        border-color: ${({ highlight, theme }) => (['email'].includes(highlight) ? theme.color.primary : theme.color.secondary)};
      }
      &:nth-of-type(6) {
        border-bottom-right-radius: ${({ theme }) => theme.radius};
        border-right: 2px solid ${({ theme }) => theme.color.secondary};
        border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
        border-color: ${({ highlight, theme }) => (['email'].includes(highlight) ? theme.color.primary : theme.color.secondary)};
      }
      &:nth-of-type(7) {
        grid-column: 1 / 5;
        text-align: center;
      }
      &:nth-of-type(8) {
        grid-column: 1 / 3;
        grid-row: 4 / 6;
        width: 100%;
        max-height: 200px;
        background-size: cover;
        background-image: url('alex.png');
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: ${({ theme }) => theme.radius};
        opacity: ${({ highlight }) => (['me'].includes(highlight) ? 1 : 0)};

        @media(min-width: 800px) {
          grid-column: 1 / 2;
          height: 200px;
        }
      }


      &:nth-of-type(10) {
        padding: 25px 0;
        opacity: ${({ highlight }) => (['me'].includes(highlight) ? 1 : 0)};
        grid-column: 3 / 5;
        grid-row: 4 / 5;
        font-size: 18px;
        padding: 0 20px;
        line-height: 25px;
        
        @media (min-width: 800px) {
          font-size: 27px;
          line-height: 30px;
          padding: 20px;
        }
        
        & > button {
          margin-top: 10px;
          margin-left: 0;
          margin-right: 40px;
        }
      }
    }
  }
`
